<template>
  <b-container>
    <page-header name="Usuários do Sistema">
      <b-button
        variant="primary"
        @click="newUser"
      >
        <FeatherIcon icon="PlusIcon" />
        &nbsp;<span class="text-nowrap">Adicionar</span>
      </b-button>
    </page-header>

    <user-new
      :uuid="uuidUser"
      :is-active.sync="isActivated"
      @onUserSave="onUserSave()"
      @onCancel="onCancelEdit()"
    />

    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
    >
      <template #cell(type)="data">
        {{ userType.find(type => type.value === data.item.type).text }}
      </template>
      <template #cell(profiles)="data">
        <b-badge v-for="profile of data.item.profiles" :key="profile.uuid" variant="info" pill>
          {{ profile.name }}
        </b-badge>
      </template>
      <template
        #cell(action)="data"
      >
        <div class="text-center">
          <i2-icon-button
            icon="EditIcon"
            variant="outline-primary"
            class="mr-1"
            @click="setCurrentUser(data.item)"
          />
          <i2-icon-button
            fa-icon="fa-trash"
            variant="outline-danger"
            class="mr-1"
            @click="deleteUser(data.item)"
          />
        </div>
      </template>
    </Grid>
  </b-container>
</template>

<script>
import UserNew from '@/views/users/UserNew.vue'
import Grid from '@/components/i2/grid/Grid.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userType from '@/variables/userTypes'
import { BBadge } from 'bootstrap-vue'

export default {
  components: {
    Grid,
    UserNew,
    BBadge,
  },
  data() {
    return {
      uuidUser: null,
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-30',
        },
        {
          key: 'email',
          label: 'e-mail',
          class: 'w-20',
        },

        {
          key: 'type',
          label: 'Tipo',
          class: 'w-10',
        },
        {
          key: 'profiles',
          label: 'Perfis',
          class: 'w-30',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-20',
        },
      ],
      items: [],
      currentUser: {},
      isActivated: false,
      userType,
    }
  },
  watch: {
    isActivated(val) {
      if (!val) {
        this.uuidUser = null
      }
    },
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    async getUsers() {
      const response = await this.$http.get('users')
      this.items = response
    },
    setCurrentUser(user) {
      this.uuidUser = user.uuid
      this.isActivated = true
    },
    newUser() {
      this.uuidUser = null
      this.isActivated = true
    },
    onUserSave() {
      this.uuidUser = null
      this.isActivated = false
      this.getUsers()
    },
    deleteUser(user) {
      this.$bvModal.msgBoxConfirm('Por favor, confirme a exclusão do usuário.', {
        title: 'Confirm exclusão?',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async value => {
          if (value) {
            const response = await this.$http.delete(`users/${user.uuid}`)
            if (response.error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.error_message,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `O usuário ${user.name} foi removido com sucesso.`,
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.getUsers()
            }
          }
          return true
        })
    },
    onCancelEdit() {
      this.isActivated = false
      this.uuidUser = null
    },
  },
}
</script>
<style>

</style>
