<template>
  <b-modal
    id="form-sidebar"
    ref="sideBar"
    size="lg"
    :visible="isActive"
    backdrop
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    bg-variant="white"
    title="Usuários"
    right
    shadow
    ok-title="Salvar"
    cancel-title="Cancelar"
    sidebar-class="sidebar-lg"
    header-class="content-sidebar-header"
    footer-class="content-sidebar-footer"
    @ok="onSubmit"
    @cancel="onCancel"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #header="{hide}">
      <div class="d-flex justify-content-between align-items-center w-100">
        <feather-icon
          class="ml-1 cursor-pointer float-right"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
    </template>
    <template #footer="{hide}">
      <div class="p-1">
        <b-row>
          <b-col
            sm="12"
            md="12"
            class="text-right"
          >

            <!-- Form Actions -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="button"
              class="mr-2"
              @click="onSubmit"
            >

              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              type="button"
              @click="onCancel"
            >

              <font-awesome-icon
                prefix="far"
                :icon="['far', 'times-circle']"
                class="mr-50"
              />
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
    <template #default="{ hide }">
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.name"
                rules="required"
                label="Nome"
                placeholder="informe o nome do usuário"
                name="name"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.email"
                rules="required|email"
                label="E-mail (login)"
                placeholder="Login do usuário (email)"
                name="email"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-vue-select
                v-model="model.type"
                name="type"
                rules="required"
                label="Tipo do usuário"
                :options="usersTypes"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-multiple-select
                v-model="model.profiles"
                name="profile"
                rules="required"
                label="Perfis do Usuário"
                :options="profilesAvailable"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="6"
            >
              <i2-form-password
                v-model="model.password"
                label="Senha"
                name="password"
                :rules="passwordValidator"
              />
            </b-col>
            <b-col sm="12"
                   md="6"
            >
              <i2-form-password
                v-model="model.confirm_password"
                label="Informe novamente a Senha"
                name="confirme a senha"
                :rules="confirmPasswordValidator"
              />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import { alphaNum, email, required } from '@validations'
import Ripple from 'vue-ripple-directive'
import userType from '@/variables/userTypes'
import I2FormInput from '@/components/i2/forms/elements/I2FormInput.vue'
import I2FormPassword from '@/components/i2/forms/elements/I2FormPassword.vue'
import I2FormVueSelect from '@/components/i2/forms/elements/I2FormVueSelect.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    I2FormVueSelect,
    I2FormInput,
    I2FormPassword,
    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    uuid: {
      type: String,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      active: false,
      usersTypes: userType,
      passwordValidator: 'password|min:6',
      lastCompany: '',
      confirmPasswordValidator: '',
      profilesAvailable: [],
      model: {
        name: '',
        uuid: null,
        email: '',
        profiles: [],
        type: {
          value: null,
          text: 'Selecione',
        },
        password: '',
        confirm_password: '',
      },
      originalData: {},
      blankUser: {
        name: '',
        uuid: '',
        email: '',
        profiles: [],
        type: {
          value: null,
          text: 'Selecione',
        },
        password: '',
        confirm_password: '',
      },
    }
  },
  watch: {
    uuid(value) {
      this.model.uuid = value
      this.getDataUser()
    },
  },
  async mounted() {
    await this.getAvailableProfiles()
  },
  methods: {
    async getDataUser() {
      this.passwordValidator = 'password|min:6'
      this.confirmPasswordValidator = 'confirmed:Senha'
      if (!this.model.uuid) {
        this.passwordValidator = 'required|password|min:6'
        this.confirmPasswordValidator = 'required|confirmed:Senha|min:6'
        this.resetModel()
        return
      }
      const userResponse = await this.$http.get(`users/${this.model.uuid}`)
      this.model = {
        uuid: this.model.uuid,
        name: userResponse.name,
        email: userResponse.email,
        type: userType.find(type => type.value === userResponse.type),
        profiles: [],
        password: '',
        confirm_password: '',
      }
      await this.getUserProfile()
    },
    onSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.refFormObserver.validate()
        .then(async success => {
          if (success) {
            let response = {}
            const data = { ...this.model }
            data.role = this.model.type.value
            if (this.model.uuid) {
              response = await this.$http.put(`users/${this.model.uuid}`, data)
            } else {
              response = await this.$http.post('users', data)
            }
            if (response.error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.error_message,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
              return
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Usuário salvo com sucesso.',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.resetModel()
            this.$emit('onUserSave', response)
            this.$refs.refFormObserver.reset()
          }
        })
    },
    onCancel() {
      this.$emit('onCancel', true)
      this.resetModel()
      this.$refs.refFormObserver.reset()
    },
    async getAvailableProfiles() {
      const allProfiles = await this.$http.get('profiles')
      this.profilesAvailable = allProfiles.map(profile => {
        const profileNew = {
          uuid: profile.uuid,
          text: profile.name,
        }
        return profileNew
      })
    },
    async getUserProfile() {
      if (!this.model.uuid) {
        return
      }

      const profiles = await this.$http.get(`profiles/user/${this.model.uuid}`)
      this.model.profiles = profiles.map(prof => {
        prof.text = prof.name
        return prof
      })
    },
    resetModel() {
      this.model = {
        uuid: null,
        name: null,
        email: null,
        profiles: [],
        type: {
          value: null,
          text: 'Selecione',
        },
        password: '',
        confirm_password: '',
      }
    }
  },
}
</script>
